
<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!informationId">Add Information</h1>
      <h1 v-if="informationId">Edit Information</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="information()">Back To Information Pages </el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="Information Added Successfully"
      type="success"
      effect="dark"
      v-if="!informationId"
    />
    <el-alert
        title="Information Updated Successfully"
        type="success"
        effect="dark"
        v-if="informationId"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>
    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Title"
        prop="title"
      >
       <el-input
          v-model="data.title"
          type="text"
          autocomplete="off"
        />
      </el-form-item></el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Meta Tag Title"
        prop="meta_title"
      >
       <el-input
          v-model="data.meta_title"
          type="textarea"
        />
      </el-form-item></el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
  <el-form-item
  label="Status"
  prop="status">
  <el-select v-model="data.status" class="selectstatus">
  <el-option v-for="item in selectstatus" 
    :key="item.label"
    :value="item.value"
    :label="item.label"
  >
  {{item.label}}
  </el-option>
  </el-select>
  </el-form-item>
  </el-col>
   
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Slug"
        prop="slug"
      >
        <el-input
          v-model="data.slug"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Meta Tag Description"
        prop="meta_description"
      > 
      <el-input
          v-model="data.meta_description"
          type="textarea"
        />
      </el-form-item>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Meta Tag Keywords"
        prop="meta_keywords"
      > 
      <el-input
          v-model="data.meta_keywords"
          type="textarea"
        />
      </el-form-item>
  </el-col>
</el-row>     
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
        label="Description"
        prop="main_content"
      >
        <TinyMce
              v-if="loadEditor"
              v-bind:content="data.main_content"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
      </el-form-item>
  </el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item
    label="Type"
    prop="info_type">
      <el-select v-model="data.info_type" class="selectstatus">
      <el-option v-for="item in selectinfo_type" 
      :key="item.label"
      :value="item.value"
      :label="item.label"
      >
      {{item.label}}
      </el-option>
      </el-select>
    </el-form-item>
  </el-col>
</el-row>
<el-row :gutter="20" style="text-align: left;">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <el-form-item>
        <input
          id="informationId"
          v-model="informationId"
          name="informationId"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
  </el-col>
</el-row>
    </el-form>
</template>
<script>
import InformationService from "@/api/information.service";
import TinyMce from "@/components/TinyMce";

  export default {
    components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Information Title.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Information Description.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        informationId: this.$route.params.informationId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
		selectstatus:[{
          value: 'Active',
          label: 'Active'
        }, {
          value: 'Inactive',
          label: 'Inactive'
        }],
      selectinfo_type:[{
          value: 'Buyer',
          label: 'Buyer'
        }, {
          value: 'Seller',
          label: 'Seller'
        }, {
          value: 'Other',
          label: 'Other'
        }, {
          value: 'Web',
          label: 'Web'
        }],
        fileList: [],
        data: {
          title: null,
          main_content: null,
          slug: null,
          meta_title: null,
          meta_description: null,
          meta_keywords: null,
          status: "Active",
          info_type: "Other",
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          title: [
            { validator: checkName, trigger: 'blur' }
          ],
          main_content: [
            { validator: checkDescription, trigger: 'blur' }
          ],         
          slug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    if(this.informationId){
      this.getinformation();
    }else{
	this.data.main_content = '<section class="info"><div class="container">Add Page Content Here!</div></section>';
	}
  },  
  computed: {
      loadEditor: function () {
        return !this.informationId || (this.informationId && this.data.main_content);
      },
  },
    methods: {
       updateTextAreaValue(value) {
        this.data.main_content = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.informationId) {
             return InformationService.add(this.data).then(response => {
                // this.data = response.data
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.informationId) {
            return InformationService.update(this.informationId, this.data).then(response => {
				console.log(response);
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	information(){
		this.$router.push("/information");
	},
	getinformation(){
    return InformationService.get(this.informationId).then(response => {
        this.data = response.data.data;
        return response;
      });
	},
   
  }
}
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 0px;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>